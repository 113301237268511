export const environment = {
  production: true,
  serverURL: 'https://api.genie.click/api/v1.0',
  link: 'https://admin.genie.click',
  AWS: {
    ACCESSKEY: "AKIAY2ONRMLVYY3ODNKP",
    SECRETACCESSKEY: "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",
    REGION: "us-east-1",
  },
  onePageLink: 'https://admin.genie.click',
  BUCKET_NAME: 'genie.click',
  BUCKET_FOLDER_NAME: 'admin',
  SOCKET_ENDPOINT: 'https://api.genie.click',
  DEFAULT_IMAGE: 'https://s3.amazonaws.com/genie.click/admin/Images/image.png',
  CURRENCY_LABEL: "JOD",
  CURRENCY_SYMBOL: "ﺩ.ﺍ",
  MERCHANTID: "TEST9800084500",
  firebase: {
    apiKey: "AIzaSyAZVffTqdmphzy24VdC0Ag5mm_ZIJN6lfk",
    authDomain: "genie-click.firebaseapp.com",
    projectId: "genie-click",
    storageBucket: "genie-click.appspot.com",
    messagingSenderId: "191310342694",
    appId: "1:191310342694:web:d543727978d51238e294e7",
    measurementId: "G-VZVLCHJQ9M",
  },
};

