import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTablesModule} from 'angular-datatables';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {NgImageSliderModule} from 'ng-image-slider';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {Ng2TelInputModule} from 'ng2-tel-input';
// import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {TranslateModule} from '@ngx-translate/core';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {OrderModule} from 'ngx-order-pipe';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {
  SpaCategoryListingComponent
} from '../sidebar-modules/service-provider/spa-category/spa-category-listing/spa-category-listing.component';
import {
  SpaPaymentListingComponent
} from '../sidebar-modules/shared-modules/payments/spa-payment-listing/spa-payment-listing.component';
import {
  PremiumServicesListingComponent
} from '../sidebar-modules/super-admin/premium-services/premium-services-listing/premium-services-listing.component';
import {
  AdvertisementRequestComponent
} from '../sidebar-modules/super-admin/requests/advertisement-request/advertisement-request.component';
import {
  AdvertisersRequestComponent
} from '../sidebar-modules/super-admin/requests/advertisers-request/advertisers-request.component';
import {
  CategoryRequestComponent
} from '../sidebar-modules/super-admin/requests/category-request/category-request.component';
import {
  CompanyPageRequestComponent
} from '../sidebar-modules/super-admin/requests/company-page-request/company-page-request.component';
import {
  ServiceProviderRequestComponent
} from '../sidebar-modules/super-admin/requests/service-provider-request/service-provider-request.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {BookingTabContentComponent} from './components/booking-tab-content/booking-tab-content.component';
import {CategoryFilterComponent} from './components/category-filter/category-filter.component';
import {ChatViewComponent} from './components/chat-view/chat-view.component';
import {CommentViewComponent} from './components/comment-view/comment-view.component';
import {DateFilterComponent} from './components/date-filter/date-filter.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {ImageSliderComponent} from './components/image-slider/image-slider.component';
import {SearchbarComponent} from './components/searchbar/searchbar/searchbar.component';
import {ServiceProviderFilterComponent} from './components/service-provider-filter/service-provider-filter.component';
import {SharedAddCategoryComponent} from './components/shared-add-category/shared-add-category.component';
import {SharedAddServicesComponent} from './components/shared-add-services/shared-add-services.component';
import {SharedEditCategoryComponent} from './components/shared-edit-category/shared-edit-category.component';
import {TableComponent} from './components/table/table.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {GooglePlacesComponent} from './google-places/google-places.component';
import {BarChartComponent} from './graphs/bar-chart/bar-chart.component';
import {LineChartComponent} from './graphs/line-chart/line-chart.component';
import {PieChartComponent} from './graphs/pie-chart/pie-chart.component';
import {Pie2ChartComponent} from './graphs/pie2-chart/pie2-chart.component';
import {PolarAreaChartComponent} from './graphs/polar-area-chart/polar-area-chart.component';
import {PageAnalyzerComponent} from './page-analyzer/pa.component';
import {PreviewComponent} from './preview/preview.component';
import {SharedRoutingModule} from './shared-routing.module';
import {TransactionSummaryComponent} from './transaction-summary/transaction-summary.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';


// import {AppModule} from "../app.module"
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};
@NgModule({
  declarations: [
    TableComponent,
    DropdownComponent,
    GooglePlacesComponent,
    ToggleSwitchComponent,
    TabsComponent,
    BookingTabContentComponent,
    ChatViewComponent,
    CommentViewComponent,
    CategoryRequestComponent,
    AdvertisersRequestComponent,
    CompanyPageRequestComponent,
    ServiceProviderRequestComponent,
    SharedAddCategoryComponent,
    SharedEditCategoryComponent,
    SharedAddServicesComponent,
    SpaCategoryListingComponent,
    PremiumServicesListingComponent,
    SpaPaymentListingComponent,
    PreviewComponent,
    SearchbarComponent,
    BarChartComponent,
    PieChartComponent,
    Pie2ChartComponent,
    PageAnalyzerComponent,
    TransactionSummaryComponent,
    PolarAreaChartComponent,
    LineChartComponent,
    DateFilterComponent,
    CategoryFilterComponent,
    ServiceProviderFilterComponent,
    AdvertisementRequestComponent,
    ImageSliderComponent,
    UserProfileComponent,
    BreadcrumbComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent
  ],
  exports: [
    TableComponent,
    DropdownComponent,
    GooglePlacesComponent,
    ToggleSwitchComponent,
    TabsComponent,
    ChatViewComponent,
    CommentViewComponent,
    SharedAddCategoryComponent,
    SharedEditCategoryComponent,
    SharedAddServicesComponent,
    PreviewComponent,
    SearchbarComponent,
    BarChartComponent,
    PieChartComponent,
    Pie2ChartComponent,
    PageAnalyzerComponent,
    TransactionSummaryComponent,
    PolarAreaChartComponent,
    LineChartComponent,
    DateFilterComponent,
    CategoryFilterComponent,
    ServiceProviderFilterComponent,
    ImageSliderComponent,
    BreadcrumbComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    PerfectScrollbarModule,
    DataTablesModule,
    NgbModule,
    AngularMultiSelectModule,
    GooglePlaceModule,
    NgImageSliderModule,
    OrderModule,
    Ng2TelInputModule,
    TranslateModule,
    InfiniteScrollModule,

  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
})
export class SharedModule { }