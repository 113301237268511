import { RouteInfo } from "./vertical-sidebar.metadata";

const sideBarIcons = 'assets/images/icon/Superadmin_drawer_icons/';//'assets/images/icon/side_bar_icons/';
const sideBarSPAIcons = 'assets/images/icon/Superadmin_drawer_icons/SPA_drawer_icons/';

export const superAdminValues: any[] = [

    {
        path: 'dashboard',
        title: 'Dashboard',
        icon: sideBarIcons + 'Dashboard.svg',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [
        ]
    },
    {
        path: 'admins',
        title: 'Admin',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Admin.svg'
    },
    {
        path: 'services',
        title: 'Services',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Services.svg'
    },
    {
        path: 'service-provider',
        title: 'Service provider',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Serviceprovider.svg'
    },
    {
        path: 'categories',
        title: 'Categories',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Categories.svg'
    },
    {
        path: 'advertisers',
        title: 'Advertisers',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Advertisers.svg'
    },
    {
        path: 'advertisements',
        title: 'Advertisements',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Advertisements.svg'
    },
    {
        path: 'executive',
        title: 'Executive',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Executive.svg'
    },
    {
        path: 'users',
        title: 'Users',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Users.svg'
    },
    {
        path: 'requests',
        title: 'Requests',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Requests.svg'
    },
    {
        path: 'premium_service',
        title: 'Premium service',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Premiumservices.svg'
    },
    {
        path: 'payments',
        title: 'Payments',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Payments.svg'
    },
    {
        path: 'abuse',
        title: 'Abuse',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Abuse.svg'
    },
    {
        path: 'enquiries',
        title: 'Enquiries',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Enquiries.svg'
    },
    {
        path: 'logs',
        title: 'Logs',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [{
            path: '/logs/service-provider-logs',
            title: 'Service provider',
            icon: sideBarIcons + 'Serviceprovider.svg',
            class: '',
            label: '',
            labelClass: "side-badge badge badge-info",
            extralink: false,
            submenu: []
        },
        {
            path: '/logs/admin-logs',
            title: 'Admin',
            class: '',
            label: '',
            labelClass: "side-badge badge badge-info",
            extralink: false,
            submenu: [
            ],
            icon: sideBarIcons + 'Admin.svg',
        },
        {
            path: '/logs/user-logs',
            title: 'User',
            class: '',
            label: '',
            labelClass: "side-badge badge badge-info",
            extralink: false,
            submenu: [
            ],
            icon: sideBarIcons + 'Users.svg',
        },
        {
            path: '/logs/staff-logs',
            title: 'Executive',
            class: '',
            label: '',
            labelClass: "side-badge badge badge-info",
            extralink: false,
            submenu: [],
            icon: sideBarIcons + 'Executive.svg',
        },
        {
            path: '/logs/advertiser-logs',
            title: 'Advertiser',
            class: '',
            label: '',
            labelClass: 'side-badge badge badge-info',
            extralink: false,
            submenu: [],
            icon: sideBarIcons + 'Advertisers.svg'
        }],
        icon: sideBarIcons + 'Logs.svg'
    },
    {
        path: 'master-collection',
        title: 'Master collection',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        icon: sideBarIcons + 'Mastercollection.svg',
        // type: 'link',
        submenu: [

            {
                path: '/master-collection/manage-cms',
                title: 'Manage CMS',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarIcons + 'Services.svg',
                submenu: []
            },

            //
            // {
            //     path: '/master-collection/default-location-enabling',
            //     // title: 'Default location enabling',
            //     title: 'Tracking settings',
            //     class: '',
            //     label: '',
            //     labelClass: 'side-badge badge badge-info',
            //     extralink: false,
            //     icon: sideBarIcons + 'Services.svg',
            //     submenu: []
            // },
            // {
            //     path: '/master-collection/taxes-charges',
            //     title: 'Taxes and charges',
            //     class: '',
            //     label: '',
            //     labelClass: 'side-badge badge badge-info',
            //     extralink: false,
            //     icon: sideBarIcons + 'Services.svg',
            //     submenu: []
            // },
            // {
            //     path: '/master-collection/advertiser-slots',
            //     title: 'Advertiser slots',
            //     class: '',
            //     label: '',
            //     labelClass: 'side-badge badge badge-info',
            //     extralink: false,
            //     icon: sideBarIcons + 'Advertisers.svg',
            //     submenu: []
            // },
            {
                path: '/master-collection/premium-services',
                title: 'Premium services',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarIcons + 'Premiumservices.svg',
                submenu: []
            },
            {
                path: '/master-collection/admin-roles',
                title: 'Admin roles',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarIcons + 'Executive.svg',
                submenu: []
            }
            , {
                path: '/master-collection/service-area-radius',
                title: 'Service area radius',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarIcons + 'Executive.svg',
                submenu: []
            }
        ]
    },
    {
        path: 'notifications',
        title: 'Notifications',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        icon: sideBarIcons + 'Notifications.svg',
        // type: 'link',
        submenu: [],
    }
];
export const serviceProvider: any[] = [

    {
        path: 'dashboard',
        title: 'Dashboard',
        icon: sideBarIcons + 'Dashboard.svg',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [
            //     {
            //         path: '/dashboard/dashboard1',
            //         title: 'Modern',
            //         icon: 'mdi mdi-adjust',
            //         class: '',
            //         label: '',
            //         labelClass: '',
            //         extralink: false,
            //         submenu: []
            //     },
            //     {
            //         path: '/dashboard/dashboard2',
            //         title: 'Classic',
            //         icon: 'mdi mdi-adjust',
            //         class: '',
            //         label: '',
            //         labelClass: '',
            //         extralink: false,
            //         submenu: []
            //     },
            //     {
            //         path: '/dashboard/dashboard3',
            //         title: 'Analytical',
            //         icon: 'mdi mdi-adjust',
            //         class: '',
            //         label: '',
            //         labelClass: '',
            //         extralink: false,
            //         submenu: []
            //     }
        ]
    },
    {
        path: 'service-requests',
        title: 'Service requests',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Admin.svg'
    },
    {
        path: 'executive',
        title: 'Executive',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Executive.svg'
    },
    {
        path: 'spadmins',
        title: 'Admin',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Admin.svg'
    },
    {
        path: 'bookings',
        title: 'Bookings',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Bookings.svg'
    },
    {
        path: 'spa-categories',
        title: 'Categories',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Categories.svg'
    },
    {
        path: 'paid-services',
        title: 'Paid services',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [{
            path: 'paid-services/featured',
            title: 'Featured service',
            icon: sideBarSPAIcons + 'Servicerequests.svg',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
        },
        {
            path: 'paid-services/company-page',
            title: 'Company page',
            class: '',
            label: '',
            labelClass: 'side-badge badge badge-info',
            extralink: false,
            submenu: [
            ],
            // type: 'link',
            icon: sideBarSPAIcons + 'Servicerequests.svg'
        },
        {
            path: 'paid-services/advertisement',
            title: 'Advertisement',
            class: '',
            label: '',
            labelClass: 'side-badge badge badge-info',
            extralink: false,
            submenu: [
            ],
            // type: 'link',
            icon: sideBarSPAIcons + 'Servicerequests.svg'
        }],
        // type: 'link',
        icon: sideBarSPAIcons + 'Servicerequests.svg'
    },
    {
        path: 'payments',
        title: 'Payments',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Payments.svg'
    },
    {
        path: 'ratings',
        title: 'Ratings',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Ratings.svg'
    },
    {
        path: 'abuse',
        title: 'Abuse',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarSPAIcons + 'Abuse.svg'
    },
    {
        path: 'logs-spa',
        title: 'Logs',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [
            {
                path: '/logs-spa/admin-spa-logs',
                title: 'Admin',
                class: '',
                label: '',
                labelClass: "side-badge badge badge-info",
                extralink: false,
                submenu: [],
                icon: sideBarIcons + 'Logs.svg',
            },
            {
                path: '/logs-spa/staff-spa-logs',
                title: 'Executive',
                class: '',
                label: '',
                labelClass: "side-badge badge badge-info",

                extralink: false,
                submenu: [],
                icon: sideBarSPAIcons + 'Executive.svg',
            }],
        icon: sideBarSPAIcons + 'Logs.svg'
    },
    {
        path: 'master-spa-collection',
        title: 'Master collection',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        icon: sideBarSPAIcons + 'Mastercollection.svg',
        // type: 'link',
        submenu: [
            {
                path: '/master-spa-collection/admin-spa-roles',
                title: 'Admin roles',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarSPAIcons + 'Admin.svg',
                submenu: []
            }, {
                path: '/master-spa-collection/location-spa-enabling',
                title: 'Tracking settings',
                class: '',
                label: '',
                labelClass: 'side-badge badge badge-info',
                extralink: false,
                icon: sideBarSPAIcons + 'Mastercollection.svg',
                submenu: []
            }
        ]
    }
];
export const advertiser: any[] = [
    {
        path: 'dashboard',
        title: 'Dashboard',
        icon: sideBarIcons + 'Dashboard.svg',

        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: []

    },


    {
        path: 'advertisers-login/advertisements',
        title: 'Advertisement',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Advertisements.svg'
    },
    {
        path: 'payments',
        title: 'Payments',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'Payments.svg'
    },
    {
        path: 'settings',
        title: 'Settings',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'settings.svg'
    },
]

export const commonMenu: RouteInfo[] = [

    {
        path: 'dashboard',
        title: 'Dashboard',
        icon: 'Home',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: []

    },
    {
        path: 'categories',
        title: 'Categories',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'admins.svg'
    },
    {
        path: 'executive',
        title: 'Executive',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'admins.svg'
    },
    {
        path: 'payments',
        title: 'Payments',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'admins.svg'
    },
    {
        path: 'abuse',
        title: 'Abuse',
        class: '',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,
        submenu: [],
        // type: 'link',
        icon: sideBarIcons + 'payments.svg'
    },
]
