import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../services/Utils/utility.service';
import { AuthService } from '../services/auth-service/auth.service';
import { SocketioService } from '../services/socket/socketio.service';
import { VerticalSidebarService } from '../vertical-sidebar/vertical-sidebar.service';
import {NotificationService} from "../services/notifications/notification.service";
declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss']

})
export class VerticalNavigationComponent implements OnInit, AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  onDeactivateSubscription: Subscription

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  // This is for Notifications
  notifications = [];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Arabic',
      code: 'ar',
      type: 'AR',
      icon: 'ar'
    },
  ]
  personData: any;
  role: string;
  userData: any;
  public sum = 100;
  public throttle = 300;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public direction = "";
  pageNumber = 1;
  isLastPage = false;


  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private socketService: SocketioService,
    private sidebarService: VerticalSidebarService, private spinner: NgxSpinnerService, private utilService: UtilityService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
    this.translate.addLangs(['en', 'ar']);
    const language = localStorage.getItem('language');
    if (language) {
      translate.setDefaultLang(language);
      translate.use(language);
      this.selectedLanguage = this.languages.find(item => item.code === language);
      this.utilService.changeDirection(language === 'en' ? 'ltr' : 'rtl');
    } else {
      translate.setDefaultLang('en');
      translate.use('en');
      this.selectedLanguage = this.languages[0];
    }

  }
  ngOnInit(): void {
    const personData = this.authService.getPersonData()
    const roleId: any = this.authService.getPersonId()
    let loggedInPersonId = personData?.data?._id

    if (roleId == 4) {
      loggedInPersonId = personData?.data?.userId
    }
    if (roleId == 5) {
      loggedInPersonId = personData?.data?.userId
    }
    this.socketService.setupSocketConnection();
    this.onDeactivateSubscription = this.socketService.setDeactivateUser().subscribe(async (res: any) => {

      if (res && res?._id === loggedInPersonId) {
        if (!res?.isActive) {
          this.authService.logout({})
        }
      }

    })
    this.personData = this.authService.getPersonData()

  }
  changeLanguage(lang: any) {

    let requestData = {
      language: lang.code
    }

    this.authService.changeLanguage(requestData).subscribe(resp => {
      this.translate.use(lang.code)
      this.selectedLanguage = lang;
      this.utilService.changeDirection(lang.code === 'en' ? 'ltr' : 'rtl');
      localStorage.setItem('language', lang.code)

      localStorage.setItem('token', resp.data.accessToken);
      this.authService.setAccessModules(resp?.data?.pages);
      window.location.reload();

    })

  }

  ngAfterViewInit() {
  }
  onProfile() {
    this.userData = this.authService.getPersonData();
    this.role = this.userData.role === 1 ? 'Super admin' : ''
    if (this.userData.role === 1) {
      return this.router.navigate([`/profile`])
    }
    if (this.userData.role === 2) {
      return this.router.navigate([`/service-provider/edit/${this.userData.data._id}`])
    }
    if (this.userData.role === 3) {
      return this.router.navigate([`/advertisers/edit`])
    }
    if (this.userData.role === 4) {
      return this.router.navigate([`/admins/edit/${this.userData.data.userId}`])
    }
    if (this.userData.role === 5) {
      return this.router.navigate([`/spadmins/editspa/${this.userData.data.userId}`])
    }
  }
  logout() {
    this.spinner.show();

    this.authService.logout({}).subscribe(async (res: any) => {
      localStorage.clear();
      window.location.href = `${environment.onePageLink}`
    });

    this.sidebarService.items.next([]);
    this.spinner.hide();

  }

  getAllNotifications() {
    this.pageNumber = 1;
    this.getNotifications();
  }

  getNotifications() {
    this.notifications = [];
    this.notificationService.getNotificationsByUser(this.pageNumber).subscribe((res: any) => {
        this.notifications = res?.data;
        this.isLastPage = res?.hasNextPage;
    })
  }

  onScrollDown() {
    if(!this.isLastPage) {
      this.direction = "down";
      this.pageNumber--;
      if(this.pageNumber < 1) {
        this.pageNumber = 1;
      }
      this.getNotifications();
    }
  }

  onUp() {
    if(!this.isLastPage) {
      this.direction = "up";
      this.pageNumber++;
      this.getNotifications();
    }
  }

  onNotificationClick(notification) {
    if(notification?.click_action) {
      window.open(notification?.click_action, '_blank');
    }
  }
}
