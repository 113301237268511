export const CONSTANTS = {
  MAX_LENGTH_CATEGORY: 25,
  MAX_LENGTH_INPUT: 50,
  MAX_LENGTH_REGISTRATION_NUMBER: 15,
  DATE_FORMAT: "DD/MM/YYYY",
  HOURS_12: "HH:MM A",
  VALID_IMG_EXTENSION: ["png", "jpg", "jpeg", "svg"],
  PASSWORD_VALIDATOR:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  PASSWORD_VALIDATION_EDUCATE_STRING:
    "Password must contain at least 1 uppercase & lowercase alphabetical character, at least 1 numeric character, at least 1 special character and must be eight characters or longer",
  DEFAULT_IMAGE:
    "https://anyrepair.s3.amazonaws.com/admin/profile/no_image_view_all_screen.png",
  MAX_LENGTH_NUMBER_WITH_COUNTRY_CODE: 15,
  MIN_LENGTH_NUMBER_WITH_COUNTRY_CODE: 7,
  MIN_LENGTH_NUMBER: 6,
  MAX_LENGTH_NUMBER: 12,
  MAX_LENGTH_SubCATEGORY: 75
};
