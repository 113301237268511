import { Routes } from "@angular/router";
import { BlankComponent } from "./layouts/blank/blank.component";
import { FullComponent } from "./layouts/full/full.component";
import { PaymentFailedComponent } from "./shared/components/payment-failed/payment-failed.component";
import { PaymentSuccessComponent } from "./shared/components/payment-success/payment-success.component";
import { UserProfileComponent } from "./shared/components/user-profile/user-profile.component";
import { AuthService as AuthGuard } from "./shared/services/auth-service/auth.service";

export const Approutes: Routes = [
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "",
        redirectTo: "/authentication/login/service-provider",
        pathMatch: "full",
      },
      {
        path: "authentication",
        redirectTo: "/authentication/login/service-provider",
        pathMatch: "full",
      },
      {
        path: "login/:type",
        redirectTo: "/authentication/login/service-provider",
        pathMatch: "full",
      },
      {
        path: "signout",
        redirectTo: "/authentication/signout",
        pathMatch: "full",
      },
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: "signup",
        redirectTo: "/signup/spa",
        pathMatch: "full",
      },
      {
        path: "signup",

        loadChildren: () =>
          import("./authentication/signup/signup.module").then(
            (m) => m.SignupModule
          ),
      },
    ],
  },
  {
    path: "",
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      // super-admin
      // {
      // 	path: "dashboard",
      // 	canActivate: [AuthGuard],
      // 	loadChildren: () =>
      // 		import("./dashboards/dashboard.module").then(
      // 			(m) => m.DashboardModule
      // 		),
      // },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/shared-modules/dashboard/dashboard.module"
          ).then((m) => m.DashboardModule),
      },
      {
        path: "profile",
        canActivate: [AuthGuard],
        component: UserProfileComponent,
        data: {
          title: "Edit profile / تعديل الملف الشخصي",
        },
        // canActivate: [AuthGuard],
        // loadChildren: () =>
        // 	import("./sidebar-modules/shared-modules/dashboard/dashboard.module").then(
        // 		(m) => m.DashboardModule
        // 	),
      },
      {
        path: "admins",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./sidebar-modules/super-admin/admin/admin.module").then(
            (m) => m.AdminModule
          ),
      },
      {
        path: "services",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/super-admin/services/services.module").then(
            (m) => m.ServicesModule
          ),
      },
      {
        path: "service-provider",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/service-provider/service-provider.module"
          ).then((m) => m.ServiceProviderModule),
      },

      {
        path: "advertisers",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/advertisers/advertisers.module"
          ).then((m) => m.AdvertisersModule),
      },
      {
        path: "advertisers-login/advertisements",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/Advertiser/advertiser-login.module").then(
            (m) => m.AdvertiserLoginModule
          ),
      },
      {
        path: "advertisements",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/advertisements/advertisements.module"
          ).then((m) => m.AdvertisementsModule),
      },

      {
        path: "users",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/super-admin/users/users.module").then(
            (m) => m.UsersModule
          ),
      },

      {
        path: "requests",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/super-admin/requests/requests.module").then(
            (m) => m.RequestsModule
          ),
      },

      {
        path: "premium_service",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/premium-services/premium-services.module"
          ).then((m) => m.PremiumServicesModule),
      },

      {
        path: "enquiries",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/enquiries/enquiries.module"
          ).then((m) => m.EnquiriesModule),
      },
      {
        path: "logs",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/super-admin/logs/logs.module").then(
            (m) => m.LogsModule
          ),
      },

      {
        path: "notifications",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/notifications/notifications.module"
          ).then((m) => m.NotificationsModule),
      },

      {
        path: "payout",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./sidebar-modules/super-admin/payout/payout.module").then(
            (m) => m.PayoutModule
          ),
      },
      // End super-admin

      // Service Provider

      {
        path: "bookings",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/bookings/bookings.module"
          ).then((m) => m.BookingsModule),
      },
      {
        path: "spadmins",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./sidebar-modules/service-provider/spa/spa.module").then(
            (m) => m.SpaModule
          ),
      },
      {
        path: "service-requests",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/service-requests/service-requests.module"
          ).then((m) => m.ServiceRequestsModule),
      },
      {
        path: "paid-services",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/paid-services/paid-services.module"
          ).then((m) => m.PaidServicesModule),
      },
      {
        path: "ratings",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/ratings/ratings.module"
          ).then((m) => m.RatingsModule),
      },
      {
        path: "logs-spa",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/logs-spa/logs-spa.module"
          ).then((m) => m.LogsSpaModule),
      },
      {
        path: "spa-categories",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/spa-category/spa-category.module"
          ).then((m) => m.SpaCategoryModule),
      },
      {
        path: "master-spa-collection",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./sidebar-modules/service-provider/master-spa-collection/master-spa-collection.module"
          ).then((m) => m.MasterSpaCollectionModule),
      },
      // End Service Provider

      // shared modules

      {
        path: "master-collection",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/super-admin/master-collection/master-collection.module"
          ).then((m) => m.MasterCollectionModule),
      },

      {
        path: "abuse",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./sidebar-modules/shared-modules/abuse/abuse.module").then(
            (m) => m.AbuseModule
          ),
      },
      {
        path: "payments",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/shared-modules/payments/payments.module"
          ).then((m) => m.PaymentsModule),
      },

			{
				path: "executive",
				canActivate: [AuthGuard],
				loadChildren: () =>
					import("./sidebar-modules/shared-modules/executive/executive.module").then(
						(m) => m.ExecutiveModule
					),
			},

      {
        path: "categories",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import(
            "./sidebar-modules/shared-modules/categories/categories.module"
          ).then((m) => m.CategoriesModule),
      },
      // end shared modules

      //advertiser

      {
        path: "settings",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./sidebar-modules/Advertiser/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "payment-success",
        component: PaymentSuccessComponent,
      },
      {
        path: "payment-failed",
        component: PaymentFailedComponent,
      },

      ///

      // {
      // 	path: "starter",
      // 	loadChildren: () =>
      // 		import("./starter/starter.module").then((m) => m.StarterModule),
      // },
      // {
      // 	path: "component",
      // 	loadChildren: () =>
      // 		import("./component/component.module").then(
      // 			(m) => m.ComponentsModule
      // 		),
      // },
      // {
      // 	path: "cards",
      // 	loadChildren: () =>
      // 		import("./cards/cards.module").then((m) => m.CardsModule),
      // },
      // {
      // 	path: "icons",
      // 	loadChildren: () =>
      // 		import("./icons/icons.module").then((m) => m.IconsModule),
      // },
      // {
      // 	path: "forms",
      // 	loadChildren: () =>
      // 		import("./form/forms.module").then((m) => m.FormModule),
      // },
      // {
      // 	path: "tables",
      // 	loadChildren: () =>
      // 		import("./table/tables.module").then((m) => m.TablesModule),
      // },
      // {
      // 	path: "charts",
      // 	loadChildren: () =>
      // 		import("./charts/charts.module").then((m) => m.ChartModule),
      // },
      // {
      // 	path: "widgets",
      // 	loadChildren: () =>
      // 		import("./widgets/widgets.module").then((m) => m.WidgetsModule),
      // },
      // {
      // 	path: "ecom",
      // 	loadChildren: () =>
      // 		import("./ecommerce/ecom.module").then((m) => m.EcomModule),
      // },
      // {
      // 	path: "timeline",
      // 	loadChildren: () =>
      // 		import("./timeline/timeline.module").then((m) => m.TimelineModule),
      // },
      // {
      // 	path: "extra-component",
      // 	loadChildren: () =>
      // 		import("./extra-component/extra-component.module").then(
      // 			(m) => m.ExtraComponentModule
      // 		),
      // },
      // {
      // 	path: "apps",
      // 	loadChildren: () =>
      // 		import("./apps/apps.module").then((m) => m.AppsModule),
      // },
      // {
      // 	path: "apps/email",
      // 	loadChildren: () =>
      // 		import("./apps/email/mail.module").then((m) => m.MailModule),
      // },
      // {
      // 	path: "maps",
      // 	loadChildren: () =>
      // 		import("./maps/maps.module").then((m) => m.MapsModule),
      // },
      // {
      // 	path: "sample-pages",
      // 	loadChildren: () =>
      // 		import("./sample-pages/sample-pages.module").then(
      // 			(m) => m.SamplePagesModule
      // 		),
      // },
      // {
      // 	path: "sub-child",
      // 	loadChildren: () =>
      // 		import("./sub-child/sub-child.module").then((m) => m.SubchildModule),
      // },
    ],
  },

  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];
