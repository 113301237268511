import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {error} from "protractor";
const AUTH_API = environment.serverURL;
interface loginCreds {
  email: string,
  password: string,
  role: string,
  timezone: string,
  utcOffset: number
}
interface forgetPassword {
  success: Boolean,
  message: string
}

interface ApiResponse {
  status: boolean,
  message: string,
  data: any
}

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`
});

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate, CanActivateChild {

  private userRole = new Subject();
  userRoleNumber: any
  pages: any[] = []
  _userRole = this.userRole.asObservable();
  private showAlert = new BehaviorSubject(false);
  constructor(
    private _http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,

  ) {
  }
  user() {
    const user = this.getPersonData()
    this.userRoleNumber = user?.role;
    this.pages = this.getAccessModule()
  }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.user()
    this.addProfilePage(this.userRoleNumber)
    const activeRoute = route?.url[0]?.path

    const isAllowed = this.pages?.filter(page => page?.path === activeRoute)

    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['/login'])
      return false;
    }
  }
  canActivateChild(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['/login'])
      return false;
    }
  }
  isAuthScreenValid(): boolean {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/dashboard'])
    } else {
      return true;
    }
  }
  // API
  login(credentials: loginCreds) {

    return this._http.post<any>(AUTH_API + '/auth/login', credentials);
  }
  logout(payload) {
    // window.location.href = 'https://any.repair'
    // window.location.href = 'https://anyrepair.5dtesting.com'
    //window.location.href = `${environment.onePageLink}`
    return this._http.post<any>(AUTH_API + '/auth/logout', payload);
  }

  // Local storage
  getToken() {
    return localStorage.getItem('token');
  }

  getPersonId() {
    return localStorage.getItem('person-id');
  }
  isLoggedIn() {
    return !!localStorage.getItem('token');
  }

  setToken(token: string) {
    return localStorage.setItem('token', token);
  }
  setPersonType(personType: any) {
    return localStorage.setItem('person-type', personType);
  }

  setRoleAccess(role: any) {
    return localStorage.setItem('person-role', role);
  }

  setPersonId(personId: any) {
    this.userRole.next(personId)
    return localStorage.setItem('person-id', personId);
  }


  setAccessModules(modules: any) {
    return localStorage.setItem('access-modules', JSON.stringify(modules));
  }
  getAccessModules() {
    return JSON.parse(localStorage.getItem('access-modules'))
  }
  setNewAdvertiserCount(count: any) {
    return localStorage.setItem("new-advertiser-count", count);
  }


  setPersonData(personData) {
    return localStorage.setItem('person-data', JSON.stringify(personData));
  }
  getPersonData() {
    return JSON.parse(localStorage.getItem('person-data'))
  }
  getAccessModule() {
    return JSON.parse(localStorage.getItem('access-modules'));
  }
  addDeviceDetails(payload) {
    return this._http.post(AUTH_API + '/auth/add-device', payload)
  }
  addProfilePage(user) {
    switch (user) {
      case 1 || '1': this.pages?.push({ path: 'profile' })
        break
      case 2 || '2': this.pages?.push({ path: 'service-provider' }); this.pages?.push({ path: 'edit' })
        break
      case 3 || '3':
        this.pages?.push({ path: 'advertisers' });
        this.pages?.push({ path: 'advertisers-login' })
        break
      case 4 || '4':
        // this.pages.push({ path: 'service-provider' }); this.pages.push({ path: 'edit' })
        break
      case 5 || '5':
        // this.pages.push({ path: 'service-provider' }); this.pages.push({ path: 'edit' })
        break
    }
  }
  setCurrency() {
    return this._http.post(AUTH_API + '/auth/get-default-currency', {}).pipe(map((result: any) => {
      if (result) {
        return localStorage.setItem('currency', JSON.stringify(result));
      } else {
        let data = {
          currencyLabel: environment.CURRENCY_LABEL,
          currencySymbol: environment.CURRENCY_SYMBOL
        }
        return localStorage.setItem('currency', JSON.stringify(data));
      }
    }))
  }
  getCurrencySymbol() {
    return (JSON.parse(localStorage.getItem('currency'))?.currencySymbol != null) ? (JSON.parse(localStorage.getItem('currency')).currencySymbol) : environment.CURRENCY_SYMBOL
  }
  getCurrencyLabel() {
    return (JSON.parse(localStorage.getItem('currency'))?.currencyLabel != null) ? (JSON.parse(localStorage.getItem('currency')).currencyLabel) : environment.CURRENCY_LABEL
  }
  convertCurrencyLabelToSymbol(currency) {
    if (currency == 'JOD' || currency == 'jod') {
      return 'د.أ'
    } else if (currency == 'usd' || currency == 'USD') {
      return '$'
    } else {
      return currency;
    }
  }
  forgetPassword(payload) {
    this.spinner.show()
    return this._http.post(`${AUTH_API}/auth/forget-password`, payload).pipe(map((result: forgetPassword) => {
      console.log('result', result);
      if (result.success) {
        this.spinner.hide()
        return result
      } else {
        this.spinner.hide()
        return result
      }
    }), catchError((err: any) => {
      this.spinner.hide()
      return throwError(err)
    }))
  }

  updateForgetPassword(payload) {
    this.spinner.show()
    return this._http.post(`${AUTH_API}/auth/update-forget-password`, payload).pipe(map((result: forgetPassword) => {
      console.log('result', result);

      if (result.success) {
        this.spinner.hide()

        return result
      } else {
        this.spinner.hide()
        return result

      }
    }), catchError((err: any) => {
      this.spinner.hide()
      return throwError(err)
    }))
  }

  changeLanguage(payload) {
    this.spinner.show()
    return this._http.post(`${AUTH_API}/auth/changeLanguage`, payload).pipe(map((result: ApiResponse) => {
      console.log('result', result);

      if (result.status) {
        this.spinner.hide()
        return result
      } else {
        this.spinner.hide()
        return result
      }
    }), catchError((err: any) => {
      this.spinner.hide()
      return throwError(err)
    }))
  }

  getLanguage() {
    return localStorage.getItem('language');
  }

  checkLoggedInUser(data) {
    return this._http.post<any>(`${AUTH_API}/auth/check-loggedin-user`, data, { headers }).pipe(
      map((response: any) => {
        if (response.status) {
          this.spinner.hide()
          return response
        } else {
          this.spinner.hide()
          return response
        }
      }),
      catchError((error: any) => {
        this.spinner.hide()
        return throwError(error)
      })
    );
  }

  updateLoginStatus(data: any) {
    return this._http.post<any>(`${AUTH_API}/auth/update-loggedin-user`, data, { headers }).pipe(
        map((response: any) => {
          if (response.status) {
            this.spinner.hide()
            return response
          } else {
            this.spinner.hide()
            return response
          }
        }),
        catchError((error: any) => {
          this.spinner.hide()
          return throwError(error)
        })
    );
  }

  setAlert(value: boolean) {
    this.showAlert.next(value);
  }

  getAlert() {
    return this.showAlert.asObservable();
  }
}
